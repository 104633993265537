import React from 'react'
import clsx from 'clsx'

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelComponent: string
  checked?: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({ labelComponent, ...props }) => {
  return (
    <label className="flex items-center cursor-pointer">
      <div className="relative mr-5">
        <input type="checkbox" className="sr-only" {...props} />
        <div className="w-[14px] h-[14px] flex items-center justify-center rounded-[2px] ring ring-dark-60 ring-1 bg-white">
          <svg
            className={clsx(
              'w-[14px] h-[14px] transition-opacity',
              props.checked ? 'opacity-100' : 'opacity-0'
            )}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        </div>
      </div>
      {labelComponent}
    </label>
  )
}

export default Checkbox
