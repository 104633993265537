export function generateBookingURL(
  destination: string,
  travelersCount: number,
  startingOn: Date,
  endingOn?: Date
): string {
  // If endingOn is not provided, set it to 7 days after startingOn
  const checkoutDate =
    endingOn || new Date(startingOn.getTime() + 7 * 24 * 60 * 60 * 1000)

  const params = new URLSearchParams({
    sb: '1', // mandatory
    ss: destination,
    checkin_monthday: startingOn.getDate().toString(),
    checkin_month: (startingOn.getMonth() + 1).toString(), // getMonth is zero-based
    checkin_year: startingOn.getFullYear().toString(),
    checkout_monthday: checkoutDate.getDate().toString(),
    checkout_month: (checkoutDate.getMonth() + 1).toString(), // getMonth is zero-based
    checkout_year: checkoutDate.getFullYear().toString(),
    group_adults: travelersCount.toString(),
    aid: '8034889',
  })

  return `https://www.booking.com/searchresults.html?${params.toString()}`
}
